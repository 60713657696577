/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  UseCMSDocument,
  useMobile,
  useService,
  Validators,
} from "@with-nx/hooks-n-helpers";
import { useRegion, useServerRegion } from "@with-nx/region";
import {
  AvailableIconName,
  DesignedButton,
  DesignedIcon,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { ReusableSlantedContainer } from "@with-nx/simple-ui/organisms";
import { Col, Row } from "antd";
import { useRouter } from "next/router";
import { ReactNode, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import styles from "./footer.module.css";
import { FooterResources } from "./footer-resources";
import dynamic from "next/dynamic";

export interface FooterProps {
  authenticated?: boolean;
  footerNoAngle?: boolean;
}

const NewsletterSection = () => {
  const [email, _email] = useState("");
  const [error, _error] = useState("");
  const [success, _success] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    _error("");
    _success("");
    e.preventDefault();

    if (!Validators.isValidEmailAddress(email)) {
      _error("Please enter a valid email address");
      return;
    }

    try {
      const makeRequestToAccounts = useService("accounts", {
        bypass: true,
      });

      makeRequestToAccounts("POST", "/api/v2/opportunities", {
        opportunity: {
          name: "",
          email,
          is_via_newsletter: true,
        },
      });
      /**
       * @note
       * We don't need to throw an error if they have already been subscribed.
       */
    } catch (error) {
      console.log(error);
    }
    _success("Thank you for subscribing!");
  };

  return (
    <div className="html-embed w-embed w-full">
      <div id="mc_embed_signup">
        <form
          onSubmit={handleSubmit}
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate
        >
          <div id="mc_embed_signup_scroll" className="w-full">
            <div className="flex items-center space-x-4">
              <input
                type="email"
                name="EMAIL"
                className={`email bg-brand-background border-brand-font-four border rounded-lg px-3 py-2 w-full ${styles["emailInput"]}`}
                style={{ color: "white" }}
                id="mce-EMAIL"
                placeholder="Enter you email"
                required
                onChange={(e) => _email(e.target.value)}
              />

              <div
                style={{
                  position: "absolute",
                  left: "-5000px",
                  display: "none",
                }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_a964bb10515dd632fd8afbf73_bfd03cc965"
                  tabIndex={-1}
                  defaultValue=""
                />
              </div>
              <div className="clear">
                <input
                  type="submit"
                  value="Sign Up"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className={`${styles["btnSignUp"]} btn-primary text-brand-background px-3 py-2 rounded-lg font-semibold hover:opacity-80 transition ease-in-out delay-150 h-full`}
                />
              </div>
            </div>
            {error && (
              <Rule parse="d:block pt:12 c:var(--error)"> {error}</Rule>
            )}
            {success && <Rule parse="d:block pt:12">{success}</Rule>}
          </div>
        </form>
      </div>
    </div>
  );
};

const SocialSection = () => {
  const { facebook, instagram } = useRegion();

  return (
    <Box parse="mb:12" css="d:flex a:center">
      {FooterResources.socials.map((item, i) => (
        <Box
          parse="mr:12"
          press={() => {
            if (window) {
              if (item.icon === "@instagram") {
                window.open(instagram, "_blank");
              } else if (item.icon === "@facebook") {
                window.open(facebook, "_blank");
              } else {
                window.open(item.url, "_blank");
              }
            }
          }}
          key={i}
        >
          <DesignedIcon name={item.icon as AvailableIconName} />
        </Box>
      ))}
    </Box>
  );
};

const NavigationSection = () => {
  return (
    <Row gutter={[12, 12]}>
      {FooterResources.navigation.map((group, g) => (
        <Col xs={12} key={g}>
          {group.map((item, i) => (
            <Rule
              to={item.url}
              tag="a"
              key={i}
              parse="!ls d:block c:?font3 mb:12"
              weight="400"
            >
              {item.label}
            </Rule>
          ))}
        </Col>
      ))}
    </Row>
  );
};

const SolutionSection = () => {
  return (
    <Row gutter={[12, 12]}>
      {FooterResources.solutions.map((item, i) => (
        <Col xs={12} key={item.label}>
          <Rule
            parse="!ls d:block c:?font3"
            weight="400"
            to={item.url}
            tag="a"
            key={i}
          >
            {item.label}
          </Rule>
        </Col>
      ))}
    </Row>
  );
};

const SubSection = ({ documents }: { documents: UseCMSDocument[] }) => {
  const mobile = useMobile();
  const region = useServerRegion();
  const _documents = documents.filter((item) =>
    item.regionTags?.includes(String(region?.id))
  );

  return (
    <Row gutter={[36, 36]} align="middle" justify="space-between">
      <Col xs={24} sm={24} md={8} lg={8}>
        <img
          width={100}
          height={56}
          src="/assets/logo/logo-broadway-media-primary.svg"
          alt="Broadway Media Logo"
        />
        <Rule parse="!lt c:?font4 mt:16 d:block">
          © {new Date().getFullYear()} Broadway Media Distribution, Inc. All
          rights reserved.
        </Rule>
      </Col>
      <Col xs={24} sm={24} md={12} lg={16}>
        <Box parse={`d:flex fd:column a:${mobile ? "flex-start" : "flex-end"}`}>
          <Box
            parse={`d:flex a:center fw:wrap mb:12 j:${
              mobile ? "unset" : "flex-end"
            }`}
          >
            {FooterResources.subs.map((item, i) => (
              <Rule
                tag="a"
                to={item.url}
                key={i}
                parse="!lt d:block c:?primary mt:12 mr:12"
                weight="400"
              >
                {item.label}
              </Rule>
            ))}
            {_documents.map((item, i) => (
              <Rule
                tag="a"
                to={`/legal/documents/${item.slug}`}
                key={i}
                parse="!lt d:block c:?primary mt:12 mr:12"
                weight="400"
              >
                {item.name}
              </Rule>
            ))}
          </Box>
        </Box>
      </Col>
    </Row>
  );
};

const HelpSection = ({
  home,
  start,
  calendly,
}: {
  home?: boolean;
  start: () => unknown;
  calendly: () => unknown;
}) => {
  return (
    <>
      <Rule parse="!ht d:block mb:4" size={20} tag="h6">
        How can we help?
      </Rule>
      <Box css="d:flex a:center fw:wrap">
        <DesignedButton
          size="medium"
          theme={home ? "primary" : "white"}
          label="Schedule a Meeting"
          properties={{ right: 12, top: 12 }}
          press={calendly}
        />
        <DesignedButton
          size="medium"
          theme={home ? "tetriary" : "tetriary-white"}
          label="Get Started"
          properties={{ right: 12, top: 12 }}
          press={start}
        />

        <Rule parse="!ps c:?font1 d:block mr:12 mt:12" size={16}>
          1-800-277-0343
        </Rule>
      </Box>
    </>
  );
};

export const Footer = (props: FooterProps) => {
  const mobile = useMobile();
  const local = useRegion();

  const Container = ({
    children,
    home,
  }: {
    home?: boolean;
    children: ReactNode;
  }) => {
    return home ? (
      <Box parse="pt:48 pb:48 c:#171B20">
        <div className="container">{children}</div>
      </Box>
    ) : (
      <Box parse={`p:relative oy:visible mt:24`}>
        <Box parse="p:absolute t:unset r:unset b:0 l:0 w:100% i:0 z:0" />
        <ReusableSlantedContainer
          still={true}
          gradient={["#171B20", "#171B20"]}
        >
          <Box parse="pt:48">
            <div className="container">{children}</div>
          </Box>
        </ReusableSlantedContainer>
      </Box>
    );
  };

  const router = useRouter();

  return (
    <Container home={props.footerNoAngle}>
      <Row gutter={[40, 40]}>
        {props.footerNoAngle ? (
          <>
            <Col xs={24} sm={24} md={11}>
              <HelpSection
                start={() =>
                  router.push(
                    `/contact/start?mode=get-started&referrer=${router.asPath}`
                  )
                }
                calendly={() => {
                  router.push("/contact/calendar");
                }}
                home={props.footerNoAngle}
              />
            </Col>
            {mobile ? undefined : (
              <Col md={2} xs={0} sm={0}>
                <Box css="w:100% h:100% d:flex a:center">
                  <Box css="h:100%" parse="w:1 c:#59687B o:0.3 mt:12 mb:12" />
                </Box>
              </Col>
            )}
            <Col xs={24} sm={24} md={11}>
              <Rule parse="!ht d:block mb:4" size={20} tag="h6">
                Sign up to our newsletter
              </Rule>
              <Rule parse="!ps c:?font4 d:block mb:20" size={16}>
                Stay up to date with the latest news, announcements, and
                articles
              </Rule>
              <NewsletterSection />
            </Col>
          </>
        ) : (
          <Col xs={24} sm={24} md={12}>
            <HelpSection
              start={() =>
                router.push(
                  `/contact/start?mode=get-started&referrer=${router.asPath}`
                )
              }
              calendly={() => {
                router.push("/contact/calendar");
              }}
            />
          </Col>
        )}
        {props.footerNoAngle ? (
          <Col xs={24}>
            <Box parse="h:1 w:100% c:#59687B o:0.3 mt:12 mb:12" />
          </Col>
        ) : undefined}
        <Col xs={24}>
          <Row gutter={[36, 36]}>
            <Col xs={24} sm={24} md={12} lg={8}>
              <Rule parse="!ls d:block mb:12" weight="600">
                Navigation
              </Rule>
              <NavigationSection />
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <Rule parse="!ls d:block mb:12" weight="600">
                Solutions
              </Rule>
              <SolutionSection />
            </Col>

            <Col xs={24} sm={24} md={24} lg={8}>
              <Rule parse="!ls d:block mb:12" weight="600">
                Social Links
              </Rule>
              {props.footerNoAngle ? (
                <Rule parse="!ls c:?font4 d:block mb:12">
                  Share with Theatre-Makers
                </Rule>
              ) : undefined}
              <SocialSection />

              {props.footerNoAngle ? undefined : (
                <>
                  <Rule parse="!ls d:block mb:12" weight="600" tag="h6">
                    Sign Up To Our Newsletter
                  </Rule>
                  <NewsletterSection />
                </>
              )}
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Box parse="h:1 w:100% c:#59687B o:0.3 mt:12 mb:12" />
        </Col>
        <Col xs={24}>
          <Box parse={mobile ? "mb:12" : "d:flex a:center j:flex-end mb:24"}>
            <Rule parse="!lt c:?font4 mr:12">{local.region}</Rule>
            <Rule parse="!lt c:?font4 mr:12">|</Rule>
            <Rule parse="!lt c:?font4 mr:12">Thoughts on the website?</Rule>
            <DesignedButton
              label="Send Feedback"
              size="small"
              theme="tetriary-accent"
              href="/contact"
            />
          </Box>
          <SubSection documents={[]} />
        </Col>
      </Row>
    </Container>
  );
};
